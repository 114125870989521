$primary: #7386a2;
$secondary: #a4b0c2;
$roundness: 0;

$sliderHeight: 8px;
$handleSize: 16px;
$valueWidth: 90px;
$labelMarkerHeight: 10px;

.timeline {
  background: #a20f0f;
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
  padding: 15px 0;

  &__container {
    align-items: center;
  }

  &__container:after,
  &__container:before {
    display: none;
  }

  &__heading {
    flex-basis: 0;
    flex-grow: 1;
  }

  h1 {
    margin: 0;
    font-size: 24px;
    line-height: 1.1;
  }

  &__selector button {
    background-color: #fff;
    color: #000;
    border: 2px solid #000;
    min-width: 80px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
  }

  &__selector {
    .dropdown-menu {
      left: auto;
      right: 0;
      height: 300px;
      overflow: auto;
      min-width: auto;
    }
  }

  @include breakpoint-lg {
    &__heading,
    &__slider {
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;
    }

    &__heading {
      margin-bottom: 30px;
    }
  }

}

[data-timeline] {
  &.fixed {
    position: fixed;
    width: 100%;
    left: 0;
    z-index: 5;
    background-color: #a20f0f;
    padding: 30px;
  }
}

.range-wrapper {
  &__slider {
    padding:40px 15px;
  }
}

.rangeslider {
  position: relative;
  height: $sliderHeight;
  border-radius: $roundness;
  width: 100%;
  background-color: $secondary;
  &:before {
    content:'';
    background: #8192ab;
    height:4px;
    width: 100%;
    display: block;
    position: relative;
    top:0;

  }

  &__handle {
    transition: background-color .2s;
    box-sizing: border-box;
    width: $handleSize;
    height: $handleSize;
    border-radius: 100%;
    background-color: #fff;
    touch-action: pan-y;
    cursor: pointer;
    display: inline-block;
    position: absolute;
    z-index: 3;
    top: -($handleSize/2) + ($sliderHeight/2);

    &__value {
      transition: background-color .2s,
      box-shadow .1s,
      transform .1s;
      box-sizing: border-box;
      width: $valueWidth;
      text-align: center;
      padding: 10px;
      background-color: $primary;
      border-radius: $roundness;
      color: white;
      left: -($valueWidth/2 - $handleSize/2);
      top: -55px;
      position: absolute;
      white-space: nowrap;
      border-top: 1px solid darken($primary, 10%);
      box-shadow: 0 -4px 1px rgba(black, .07),
      0 -5px 20px rgba(black, .3);

      &:before {
        transition: border-top-color .2s;
        position: absolute;
        bottom: -10px;
        left: calc(50% - 10px);
        content: "";
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid;
        border-top-color: $primary;
      }

      &:after {
        content: " cm";
      }
    }
  }

  &__fill {
    position: absolute;
    top: 0;
    z-index: 1;
    height: 100%;
    background-color: transparent;
    border-radius: $roundness;
  }

  &__labels {
    position: absolute;
    width: 100%;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    top:-40px;

    &__label {
      font-size: 0.75em;
      position: relative;
      color: #fff;
      cursor:pointer;

      &:before, &:after {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        content: "";
        width: 2px;
        height: $labelMarkerHeight;
        border-radius: 0;
        background-color:$secondary;
      }

      &:before {
        top: 25px;
      }

      &:after {
        bottom: -48px;
      }

      &:first-child:before,
      &:last-child:before{
        height: $labelMarkerHeight;
      }

      //&:first-child:before { background-color: $primary }
      //&:last-child:before { background-color: $secondary }

      //&:first-child { transform: translateX(-48%) }
      //&:last-child { transform: translateX(48%) }
    }
  }

  &__ruler {
    cursor: pointer;
    font-size: .7em;
    margin: 0 3px;
    position: absolute;
    top: -40px;
    text-align: justify;
    color:#fff;
    width: 100%;
    &:after {
      content: "";
      display: inline-block;
      width: 100%;
    }
  }

  // active state
  &.rangeslider--active {
    .rangeslider__handle {
      &, *      { background-color: lighten($primary, 10%) }
      *:before  { border-top-color: lighten($primary, 10%) }
    }

    .rangeslider__handle__value {
      transform: translateY(-5px);
      box-shadow: 0 -3px 2px rgba(black, .04),
      0 -9px 25px rgba(black, .15);
    }
  }
}
