.film-page {

  .film {
    margin-top: 0;
    position: relative;
  }

  .film__poster {
    margin-bottom: 10px;
  }

  .film__title {
    font-size: 24px;
    margin: 0 0 15px;
    line-height: 1.6;
  }

  .film__synopsis,
  .film__cast-crew,
  .film__media {
    * {
      color: #000;
    }
  }

  @include breakpoint-sm {
    .film__title {
      font-size: 48px;
      line-height: 1.1;
    }

    .film__cast-crew,
    .film__media {
      display: grid;
      justify-content: space-between;
      grid-template-columns: repeat(2, 45%);

      .slider-nav {
        padding: 10px 20px;
        background: #cccccc;

        .slick-prev {
          left: 5px;
        }
        .slick-next {
          right: 5px;
        }

        .carousel__item {
          margin-right: 10px;
        }
      }

    }

  }
}
