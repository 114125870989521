.timeline__container,
.film__item, .film__container {
  display: flex;
  flex-wrap: wrap;
}
.bg-white {
  background-color: #fff !important;
}
.embed-responsive-3by2 {
  padding-bottom: 66.6%;
}
