.film {
margin-top: 50px;

  &__item {
    margin-bottom: 50px;
  }

  &__poster,
  &__details {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }

  &__poster img,
  &__gallery img {
    width: 100%;
  }

  &__year {
    margin-bottom: 25px;
  }

  &__hero {
    padding: 60px 0 30px;
    border-top: 1px solid #ffffff;
    background: #555555;
  }

  &__hero-container {
    display: flex;
    flex-direction: column;
    &:before,
    &:after {
      display: none;
    }
  }

  &__credits, &__external-links ul, &__synopsis ul, &__cast-crew ul  {
    list-style: none;
    padding: 0;
    line-height: 1.8;

    a {
      color: #fff;
    }
  }

  &__external-links {
    h4 {
      background-color: rgba(255,255,255, 0.2);
      padding: 12px;
      font-size: 18px;
      line-height: 1.1;
      margin-block-end: 10px;
    }
    ul {
      padding: 12px 30px 30px;
      background-color: rgba(255,255,255, 0.1);
      margin-block-end: 0;
      line-height: 2.2;
    }
  }
  &__paging {
    text-align: center;
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    a {
      color: #ffffff;
    }
  }

  &__synopsis {
    padding-block-start: 30px;
  }

  &__gallery {
    .carousel__item {
      margin: 0;
    }
  }

  @include breakpoint-custom(600px) {
    &__item {
      column-gap: 30px;
    }

    &__poster {
      flex: 0 0 25%;
      max-width: 25%;
    }
    &__details {
      flex-basis: 0;
      flex-grow: 1;
    }

    &__title {
      margin-top: 0;
    }
  }

  @include breakpoint-sm {
    &__title {
      font-size: 36px;
    }
    &__year {
      font-size: 18px;
    }

    &__hero {
      padding: 30px 0;
      background: rgb(90,90,90);
      background: linear-gradient(180deg, rgba(90,90,90,1) 0%, rgba(48,48,48,1) 50%, rgba(3,3,3,1) 100%);
    }
    &__hero-container {
      flex-direction: row;
      gap: 30px;
    }

    &__external-links {
      margin-top: 50%;
    }
    &__paging {
      position: static;
      text-align: right;
      transform: none;
    }

  }

  @include breakpoint-md {
    &__poster {
      flex: 0 0 20%;
      max-width: 20%;
    }
  }
}
