@import "https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,300,300italic,700,700italic,400italic|Montserrat:100,300,400,700";
.timeline__container,
.film__item, .film__container {
  display: flex;
  flex-wrap: wrap; }

.bg-white {
  background-color: #fff !important; }

.embed-responsive-3by2 {
  padding-bottom: 66.6%; }

.timeline {
  background: #a20f0f;
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
  padding: 15px 0; }
  .timeline__container {
    align-items: center; }
  .timeline__container:after, .timeline__container:before {
    display: none; }
  .timeline__heading {
    flex-basis: 0;
    flex-grow: 1; }
  .timeline h1 {
    margin: 0;
    font-size: 24px;
    line-height: 1.1; }
  .timeline__selector button {
    background-color: #fff;
    color: #000;
    border: 2px solid #000;
    min-width: 80px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px; }
  .timeline__selector .dropdown-menu {
    left: auto;
    right: 0;
    height: 300px;
    overflow: auto;
    min-width: auto; }
  @media (min-width: 1200px) {
    .timeline__heading, .timeline__slider {
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%; }
    .timeline__heading {
      margin-bottom: 30px; } }

[data-timeline].fixed {
  position: fixed;
  width: 100%;
  left: 0;
  z-index: 5;
  background-color: #a20f0f;
  padding: 30px; }

.range-wrapper__slider {
  padding: 40px 15px; }

.rangeslider {
  position: relative;
  height: 8px;
  border-radius: 0;
  width: 100%;
  background-color: #a4b0c2; }
  .rangeslider:before {
    content: '';
    background: #8192ab;
    height: 4px;
    width: 100%;
    display: block;
    position: relative;
    top: 0; }
  .rangeslider__handle {
    transition: background-color .2s;
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background-color: #fff;
    touch-action: pan-y;
    cursor: pointer;
    display: inline-block;
    position: absolute;
    z-index: 3;
    top: -4px; }
    .rangeslider__handle__value {
      transition: background-color .2s, box-shadow .1s, transform .1s;
      box-sizing: border-box;
      width: 90px;
      text-align: center;
      padding: 10px;
      background-color: #7386a2;
      border-radius: 0;
      color: white;
      left: -37px;
      top: -55px;
      position: absolute;
      white-space: nowrap;
      border-top: 1px solid #5a6d88;
      box-shadow: 0 -4px 1px rgba(0, 0, 0, 0.07), 0 -5px 20px rgba(0, 0, 0, 0.3); }
      .rangeslider__handle__value:before {
        transition: border-top-color .2s;
        position: absolute;
        bottom: -10px;
        left: calc(50% - 10px);
        content: "";
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid;
        border-top-color: #7386a2; }
      .rangeslider__handle__value:after {
        content: " cm"; }
  .rangeslider__fill {
    position: absolute;
    top: 0;
    z-index: 1;
    height: 100%;
    background-color: transparent;
    border-radius: 0; }
  .rangeslider__labels {
    position: absolute;
    width: 100%;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    top: -40px; }
    .rangeslider__labels__label {
      font-size: 0.75em;
      position: relative;
      color: #fff;
      cursor: pointer; }
      .rangeslider__labels__label:before, .rangeslider__labels__label:after {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        content: "";
        width: 2px;
        height: 10px;
        border-radius: 0;
        background-color: #a4b0c2; }
      .rangeslider__labels__label:before {
        top: 25px; }
      .rangeslider__labels__label:after {
        bottom: -48px; }
      .rangeslider__labels__label:first-child:before, .rangeslider__labels__label:last-child:before {
        height: 10px; }
  .rangeslider__ruler {
    cursor: pointer;
    font-size: .7em;
    margin: 0 3px;
    position: absolute;
    top: -40px;
    text-align: justify;
    color: #fff;
    width: 100%; }
    .rangeslider__ruler:after {
      content: "";
      display: inline-block;
      width: 100%; }
  .rangeslider.rangeslider--active .rangeslider__handle, .rangeslider.rangeslider--active .rangeslider__handle * {
    background-color: #92a0b6; }
  .rangeslider.rangeslider--active .rangeslider__handle *:before {
    border-top-color: #92a0b6; }
  .rangeslider.rangeslider--active .rangeslider__handle__value {
    transform: translateY(-5px);
    box-shadow: 0 -3px 2px rgba(0, 0, 0, 0.04), 0 -9px 25px rgba(0, 0, 0, 0.15); }

.film {
  margin-top: 50px; }
  .film__item {
    margin-bottom: 50px; }
  .film__poster, .film__details {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%; }
  .film__poster img,
  .film__gallery img {
    width: 100%; }
  .film__year {
    margin-bottom: 25px; }
  .film__hero {
    padding: 60px 0 30px;
    border-top: 1px solid #ffffff;
    background: #555555; }
  .film__hero-container {
    display: flex;
    flex-direction: column; }
    .film__hero-container:before, .film__hero-container:after {
      display: none; }
  .film__credits, .film__external-links ul, .film__synopsis ul, .film__cast-crew ul {
    list-style: none;
    padding: 0;
    line-height: 1.8; }
    .film__credits a, .film__external-links ul a, .film__synopsis ul a, .film__cast-crew ul a {
      color: #fff; }
  .film__external-links h4 {
    background-color: rgba(255, 255, 255, 0.2);
    padding: 12px;
    font-size: 18px;
    line-height: 1.1;
    margin-block-end: 10px; }
  .film__external-links ul {
    padding: 12px 30px 30px;
    background-color: rgba(255, 255, 255, 0.1);
    margin-block-end: 0;
    line-height: 2.2; }
  .film__paging {
    text-align: center;
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%); }
    .film__paging a {
      color: #ffffff; }
  .film__synopsis {
    padding-block-start: 30px; }
  .film__gallery .carousel__item {
    margin: 0; }
  @media (min-width: 600px) {
    .film__item {
      column-gap: 30px; }
    .film__poster {
      flex: 0 0 25%;
      max-width: 25%; }
    .film__details {
      flex-basis: 0;
      flex-grow: 1; }
    .film__title {
      margin-top: 0; } }
  @media (min-width: 768px) {
    .film__title {
      font-size: 36px; }
    .film__year {
      font-size: 18px; }
    .film__hero {
      padding: 30px 0;
      background: #5a5a5a;
      background: linear-gradient(180deg, #5a5a5a 0%, #303030 50%, #030303 100%); }
    .film__hero-container {
      flex-direction: row;
      gap: 30px; }
    .film__external-links {
      margin-top: 50%; }
    .film__paging {
      position: static;
      text-align: right;
      transform: none; } }
  @media (min-width: 992px) {
    .film__poster {
      flex: 0 0 20%;
      max-width: 20%; } }

.film-page .film {
  margin-top: 0;
  position: relative; }

.film-page .film__poster {
  margin-bottom: 10px; }

.film-page .film__title {
  font-size: 24px;
  margin: 0 0 15px;
  line-height: 1.6; }

.film-page .film__synopsis *,
.film-page .film__cast-crew *,
.film-page .film__media * {
  color: #000; }

@media (min-width: 768px) {
  .film-page .film__title {
    font-size: 48px;
    line-height: 1.1; }
  .film-page .film__cast-crew,
  .film-page .film__media {
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(2, 45%); }
    .film-page .film__cast-crew .slider-nav,
    .film-page .film__media .slider-nav {
      padding: 10px 20px;
      background: #cccccc; }
      .film-page .film__cast-crew .slider-nav .slick-prev,
      .film-page .film__media .slider-nav .slick-prev {
        left: 5px; }
      .film-page .film__cast-crew .slider-nav .slick-next,
      .film-page .film__media .slider-nav .slick-next {
        right: 5px; }
      .film-page .film__cast-crew .slider-nav .carousel__item,
      .film-page .film__media .slider-nav .carousel__item {
        margin-right: 10px; } }
