@mixin section-padding($full-pad:60px) {
  padding-top: $full-pad / 2;
  padding-bottom: $full-pad / 2;
  @media (min-width: $screen-sm-min) {
    padding-top: $full-pad;
    padding-bottom: $full-pad;
  }
}
@mixin vertical-align {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@mixin breakpoint-custom($breakpoint) {
  @media (min-width: #{$breakpoint}) {
    @content;
  }
}

@mixin breakpoint-max($breakpoint) {
  @media (max-width: #{$breakpoint}) {
    @content;
  }
}

@mixin breakpoint-xs {
  @include breakpoint-custom($screen-xs) {
    @content;
  }
}

@mixin breakpoint-sm {
  @include breakpoint-custom($screen-sm) {
    @content;
  }
}

@mixin breakpoint-md {
  @include breakpoint-custom($screen-md) {
    @content;
  }
}

@mixin breakpoint-lg {
  @include breakpoint-custom($screen-lg) {
    @content;
  }
}

@mixin breakpoint-navbar {
  @include breakpoint-custom($navbar-breakpoint) {
    @content;
  }
}

@mixin breakpoint-range($min, $max) {
  @media (min-width: #{$min}) and (max-width: #{$max}) {
    @content;
  }
}

@mixin column-adjust($column,$gutter) {

  // set custom variables for our grid structure
  $grid-columns: $column !global;
  $grid-gutter-width: $gutter !global;

  .row {
    @include make-row();
  }
  @include make-grid-columns();
  // @include make-grid(xs);
  @media (min-width: $screen-sm-min) {
    @include make-grid(sm);
  }
  @media (min-width: $screen-md-min) {
    @include make-grid(md);
  }
  @media (min-width: $screen-lg-min) {
    @include make-grid(lg);
  }

  // reset global vars;
  $grid-columns: $new-columns !global;
  $grid-gutter-width: $new-gutter-width !global;

}
